<template>
  <div class="access" id="access">
    <Nav/>
      <main id="main" role="main">
        <section id="lead">
            <h2><img src="@/assets/img/head_jinjamap.svg" alt="神社MAP"></h2>
            <TxtAnime>しなの木をご覧になる方は社殿の中をお通りください。<br>“ご神木”しなの木
参拝時間は9：00～16：00です。</TxtAnime>
            <div class="box">
                <a href="./img/jinja_map.pdf" target="_blank"><img src="@/assets/img/jinja-map.jpg" alt="神社MAP"></a>
            </div>
          </section>
      </main>
    <Foot/>
  </div>
</template>

<script>
import Foot from '@/components/Foot.vue'
import Nav from '@/components/Nav.vue'
import TxtAnime from '@/components/TxtAnime.vue'

export default {
  name: 'Goshinboku',
  components: {
    Foot,
    Nav,
    TxtAnime
  }
}
</script>

<style lang="scss" scoped>
  h2 + .box {
  }
  p {
      font-family: 'NuKinakoMochi-BarVtE-1'
  }
</style>
